import React from 'react'
import './loading.scss'
export default function Loading() {
    return (
        <div id="loader">
            <div className="lds-circle"><div></div></div>
        </div>

    )
}
