import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon, TwitterShareButton, TwitterIcon} from 'react-share'
import Game from '../game/Game'
import Modal from '../../utils/modal/Modal'
import './gamePage.scss'

import motuzLogo from '../../../../images/motuz-logo.png'
import Loader from '../../utils/loader/Loader'
import GamesPortal from '../gamesPortal/GamesPortal'
var createGuest = require("cross-domain-storage/guest");
var createHost = require("cross-domain-storage/host");


const proxy = 'https://motuz.fr'

const initModal = {
    info: false,
    stats: false,
    settings: false,
    share: false
}

/* Game component */
const gamesParameters = {
    rowsPerBoard: 6,
}

export default function GamePage() {
    // Subdomain
    const [subdomain, setSubdomain] = useState(null)

    //States
    const [settings, setSettings] = useState({keyboard: 'azerty'})
    const [modal, setModal] = useState({stats: false})
    const [volume, setVolume] = useState(true)
    const [colors, setColors] = useState({good: '#D40C88', std: '#0074C2', misplaced: '#FEBF01'})
    const [explainer, setExplainer] = useState('')
    const [subdomainGameData, setSubdomainGameData] = useState({name: '', shareMsg: '', word: '', resultStatus: '', guesses: [], games: 0, tries: [], totalTries: 0, losses: 0, avg: 0})
    const {resultStatus, guesses, games, tries, totalTries, losses, avg, word} = subdomainGameData
    const [updateLS, setUpdateLS] = useState(false)

    // All records
    const [allGames, setAllGames] = useState([])
    const [allGamesPlayed, setAllGamesPlayed] = useState([])
    const [selectedRecords, setSelectedRecords] = useState('MOTUZ')

    //Loading
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const host = window.location.host;
        console.log(host)
        const arr = host.split('.')
        console.log(arr[0])
        if (arr.length === 3) {
            setSubdomain(arr[0])
            setSelectedRecords(arr[0])
            return setLoading(false)
        }

        const getAllGames = async() => {
            try {
                const res = await axios.get(`${proxy}/data/games/get/published`)
                const allSubdomains = res.data.games.map(g => g.subdomain)
                try {
                    const storageHost = createHost(allSubdomains.map(subdomain => {
                        return {
                            origin: `https://${subdomain}.motuz.fr`,
                            allowedMethods: ['get'],
                        }
                    }))
                    let allRecords = []
                    allSubdomains.forEach(subdomain => {
                        var subStorage = createGuest(`https://${subdomain}.motuz.fr`)
                        subStorage.get("motuzState", function (err, val) {
                            console.log(val)
                        })
                    })
                } catch (err) {
                    console.log(err)
                }
                setLoading(false)
            } catch (err) {
                err.response.data.msg &&
                alert(err.response.data.msg)
                setLoading(false)
            }
        }
        getAllGames()
    }, [])

    useEffect(() => {
        const ls = JSON.parse(window.localStorage.getItem("motuzState"))
        if(ls) {
            let gameLS = null
            if(subdomain) {
                gameLS = ls[subdomain]
                if(gameLS){
                    const {games, tries, totalTries, avg, losses, current, name, shareMsg} = gameLS
                    const {guesses, resultStatus, word} = current
                    setModal({...modal, stats: gameLS.current.resultStatus !== ''})
                    setSubdomainGameData({name, shareMsg, word, resultStatus, guesses, games, tries, totalTries, losses, avg})
                }
            } else{
                setAllGamesPlayed(Object.entries(ls).map(([key, value]) => {return {subdomain: key, name: value.name}}))
                selectNewRecords(ls)
            }
        }
    }, [subdomain, selectedRecords, updateLS])

    const updateLocalStorage = () => {
        setUpdateLS(!updateLS)
    }

    const changeColors = (gameColors) => {
        setColors(gameColors)
    }

    const changeExplainer = (gameExplainer) => {
        setExplainer(gameExplainer)
    }

    const changeRecords = (e) => {
        setSelectedRecords(e.target.value)
    }

    const selectNewRecords = (ls) => {
        if(selectedRecords !== 'MOTUZ') {
            const {name, shareMsg, games, tries, totalTries, avg, losses, current} = ls[selectedRecords]
                return setSubdomainGameData({name, shareMsg, word: current.word, resultStatus, guesses, games, tries, totalTries, losses, avg})
        } else {
            let tries = Array.from(Array(gamesParameters.rowsPerBoard).keys()).map(el => 0)
            let games = 0
            let totalTries = 0
            let losses = 0
            Object.keys(ls).forEach(subdomain => {
                for(let i=0; i < gamesParameters.rowsPerBoard; i++) {
                    tries[i] += ls[subdomain].tries[i]
                }
                games += ls[subdomain].games
                totalTries += ls[subdomain].totalTries
                losses += ls[subdomain].losses
            })
            return setSubdomainGameData({name: 'MOTUZ', shareMsg: 'Join me and play in MOTUZ!', word:'', games, tries, totalTries, avg: games > 0 ? Math.round(totalTries/games * 100) / 100 : 0, losses, resultStatus: ''})
        }
    }

    const concatTriesAndLosses = tries.concat([losses])
    const maximumTries = Math.max(...concatTriesAndLosses)
    const heights = concatTriesAndLosses.map(el => `${100 * (el/maximumTries)}%`)

    const shareURL = async(e) => {
        e.preventDefault()

        const shareData = {
            title: 'MOTUZ Website',
            text: subdomainGameData.shareMsg,
            url: 'https://motuz.fr'
        }

        if (navigator.share){
            try {
                await navigator.share(shareData).then(() => console.log('sharing success'));
            } catch (err) {
                console.log(err)
            }
        } else {
            const shareMsg = `MOTUZ Website\n\n${subdomainGameData.shareMsg}\n\nhttps://motuz.fr`
            const { ClipboardItem } = window
            if(ClipboardItem && navigator.clipboard.write){
                const blob = new Blob([shareMsg], { type: "text/html" })
                await navigator.clipboard.write([new ClipboardItem({ "text/html" : blob })])
            } else {
                await navigator.clipboard.writeText(shareMsg)
            }
            alert(`Copié\n\n${shareMsg}`)
        }
    }

    const resultsInBoxes = subdomainGameData?.guesses?.map(guess => guess.results.map(t => t === 'correct' ? '🟥' : '🟦').join('')).join('\n')
    const shareResultsText = `My ${subdomainGameData?.name} results for today:\n${resultsInBoxes}\nhttps://motuz.fr`
    
    const shareResults = async(e) => {
//         "I have discovered the word "TEST01" of the day ! My score: 4/6.
// My average : 3.5/6 with 125 parties played."
        e.preventDefault()
        const shareData = {
            title: 'MOTUZ Website',
            text: selectedRecords === 'MOTUZ' ? `My average: ${avg}/6 with ${games} parties played across all MOTUZ games.` : subdomain ? resultStatus === 'win' ? `I have discovered the word "${word}" of the day! My score: ${guesses.length}/${gamesParameters.rowsPerBoard}.\nMy average: ${avg}/6 with ${games} parties played on ${subdomainGameData.name}.` : `My score: ${guesses.length}/${gamesParameters.rowsPerBoard}.\nMy average: ${avg}/6 with ${games} parties played on ${subdomainGameData.name}.` : `My average: ${avg}/6 with ${games} parties played.`,
            url: `https://${selectedRecords === 'MOTUZ' ? '' : `${subdomain}.`}motuz.fr`
        }

        if (navigator.share){
            try {
                await navigator.share(shareData).then(async() => await navigator.clipboard.writeText(shareData.text));
            } catch (err) {
                console.log(err)
            }
        } else {
            const { ClipboardItem } = window
            if(ClipboardItem && navigator.clipboard.write){
                const content = shareData.text
                const blob = new Blob([content], { type: "text/html" })
                await navigator.clipboard.write([new ClipboardItem({ "text/html" : blob })])
            } else {
                await navigator.clipboard.writeText(shareData.text)
            }
            alert(`Copié\n\n${shareData.text}`)
        }
    }

    const shareButtons = (
        <div className='share-buttons'>
            {/* <FacebookShareButton
                url= 'https://motuz.fr'
                quote= {shareResultsText}
                hashtag='#Motuz'
            >
                <FacebookIcon logofillcolor="white" round={true} size={32}/>
            </FacebookShareButton>
            <TwitterShareButton
                url= 'https://motuz.fr'
                title={`Motuz Games website\n${shareResultsText}`}
                hashtags={['#Motuz']}
            >
                <TwitterIcon logofillcolor="white" round={true} size={32}/>
            </TwitterShareButton>
            <WhatsappShareButton
                url= 'https://motuz.fr'
                title={`Motuz Games website\n${shareResultsText}`}
                separator=''
            >
                <WhatsappIcon logofillcolor="white" round={true} size={32}/>
            </WhatsappShareButton> */}
        </div>
    )

    const navigateToHome = () => {
        window.location.href = `https://motuz.fr`
    }

    return (
        (loading) ? <div className='loader-game'><Loader /></div>
        :
        <div className='game-page'>
            <div id="bubble"></div>
            <Modal open={modal.info} close={()=>setModal({...initModal})} fadeIn={true}>
                <div id='modal-instructions' className='modal-container' style={{backgroundColor: '#0E4679'}}>
                    <h1 className='modal-title'>À PROPOS</h1>
                    <section className='hcenter'>
                        {explainer && <p>{explainer}</p>}
                        <div className='instr-row-example'>
                            <div className='letter-container' style={{backgroundColor: colors.std}}><p style={{backgroundColor: colors.good}}>M</p></div>
                            <div className='letter-container' style={{backgroundColor: colors.std}}><p style={{backgroundColor: colors.std}}>O</p></div>
                            <div className='letter-container' style={{backgroundColor: colors.std}}><p style={{backgroundColor: colors.std}}>T</p></div>
                            <div className='letter-container' style={{backgroundColor: colors.std}}><p style={{backgroundColor: colors.std}}>U</p></div>
                            <div className='letter-container' style={{backgroundColor: colors.std}}><p className='misplaced' style={{backgroundColor: colors.misplaced}}>Z</p></div>
                        </div>
                        <p>Vous avez six essais pour deviner le mot du jour, entre 6 et 9 lettres, commun à tous.</p>
                        <p>Vous ne pouvez proposer.</p>
                        <h2 className='modal-title2'>Le saviez-tu</h2>
                        <p>Vous avez six essais pour deviner le mot du jour, entre 6 et 9 lettres, commun à tous.</p>
                        <p>Vous ne pouvez proposer.</p>
                    </section>
                </div>
            </Modal>
            <Modal open={modal.stats} close={()=>setModal({...initModal})} fadeIn={true}>
                <div id='modal-stats' className='modal-container'>
                    {subdomain ? 
                    <div className='game-record'>
                        {resultStatus !== '' && 
                        <div className='game-results'>
                            {resultStatus === 'win' ?
                            <>
                                <h1 className='modal-title'> BRAVO !</h1>
                                <div className='guesses-container'>
                                    <div className='guesses-cards'>
                                        {guesses?.map(({results},idx) => <div key={idx} className='guess-row'>
                                            {results.map((result,idx) => <div key={idx} className='guess-card' style={{backgroundColor: result === 'correct' ? result === 'misplaced' ? colors.misplaced : colors.good : colors.std}}></div>)}
                                        </div>)}
                                    </div>
                                    <div className='guesses-out-of'>{guesses?.length} / {gamesParameters.rowsPerBoard}</div>
                                </div>
                            </>
                            :
                            <>
                                <h1 className='modal-title'>BIEN ESSAYÉ !</h1>
                                <p className='modal-subtitle'>Le mot du jour était :</p>
                                <div className='word-letters'>
                                    {word?.toUpperCase().split('').map((l,idx) => <div key={idx} className='letter-card'>{l}</div>)}
                                </div>
                            </>}
                            <div className='share' onClick={shareResults}>
                                <FontAwesomeIcon icon="fa-solid fa-share-nodes"/>
                                <p> Partager</p>
                            </div>
                            {shareButtons}
                        </div>}
                        <div className='game-stats'>
                            <h1 className='modal-title'>Tes Stats</h1>
                            <p>Moyenne: <span className='average'>{avg}</span></p>
                            <p>Parties: {games}</p>
                            <div className='graph'>
                                {tries?.map((count,idx) => {
                                    return (
                                        <div key={idx} className='graph-card'>
                                            <div className='column' style={{height: heights[idx]}}></div>
                                            <p className='try'>{idx + 1}/{gamesParameters.rowsPerBoard}</p>
                                            <p className='count'>{count}</p>
                                        </div>
                                    )
                                })}
                                <div className='graph-card'>
                                    <div className='column' style={{height: heights[heights.length - 1]}}></div>
                                    <p className='try'>X</p>
                                    <p className='count'>{losses}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    :<div className='all-games-records'>
                        <div className='game-stats'>
                            <h1 className='modal-title'>TES STATS</h1>
                            <p>Motuz' joué:</p>
                            <select onChange={changeRecords}>
                                <option value='MOTUZ'>MOTUZ</option>
                                {allGamesPlayed?.map(el => <option key={el.subdomain} value={el.name}>{el.name}</option>)}
                            </select>
                            <p>Moyenne: <span className='average'>{avg}</span></p>
                            <p>Parties: {games}</p>
                            <div className='graph'>
                                {tries?.map((count,idx) => {
                                    return (
                                        <div key={idx} className='graph-card'>
                                            <div className='column' style={{height: heights[idx]}}></div>
                                            <p>{idx + 1}/{gamesParameters.rowsPerBoard}</p>
                                            <p className='count'>{count}</p>
                                        </div>
                                    )
                                })}
                                <div className='graph-card'>
                                    <div className='column' style={{height: heights[heights.length - 1]}}></div>
                                    <p className='try'>X</p>
                                    <p className='count'>{losses}</p>
                                </div>
                            </div>
                        </div>
                        <div className='share' onClick={shareResults}>
                            <FontAwesomeIcon icon="fa-solid fa-share-nodes" />
                            <p>Partager</p>
                        </div>
                    </div>}
                </div>
            </Modal>
            <Header />
            {subdomain && window.location.host !== 'motuz.fr'?
            <div className='game-container'>
                <Game 
                    subdomain={subdomain}
                    keyboard={settings.keyboard}
                    muted={!volume} 
                    updateLS={updateLocalStorage}
                    changeColors={changeColors}
                    changeExplainer={changeExplainer}
                    changeGame={changeRecords}
                />
            </div>
            :<GamesPortal />}
            <Footer />
        </div>
    )

    function Header() {
        return (
            <header>
                <div className='header-container'>
                    <div className="header-left">
                        <div className="icon-button" onClick={()=>setModal({...initModal, info: true})}><FontAwesomeIcon icon="fa-solid fa-info" /></div>
                        <div className='icon-button' onClick={()=>setModal({...initModal, stats: true})}><FontAwesomeIcon icon="fa-solid fa-chart-simple" /></div>
                    </div>
                    <div className="header-middle">
                        <div className="motuz-title">
                            <img id='motuz-logo' src={motuzLogo} alt='motuz logo' onClick={navigateToHome}/>
                            {/*<span className='correct-title'>M</span>
                            <span className='incorrect-title'><img src={emoji} alt='emoji-smiley'/></span>
                            <span className='incorrect-title'>T</span>
                            <span className='incorrect-title'>U</span>
                            <span className='correct-title'><div className=' tilt'>Z</div></span>*/
                            }
                        </div>
                    </div>
                    <div className="header-right">
                        <div className="icon-button" onClick={()=>setVolume(!volume)}>{volume ? <FontAwesomeIcon icon="fa-solid fa-volume-high" /> : <FontAwesomeIcon id="mute" icon="fa-solid fa-volume-xmark" />}</div>
                        <div className="icon-button" onClick={shareURL}><FontAwesomeIcon icon="fa-solid fa-share-nodes" /></div>
                    </div>
                </div>
            </header>
        )
    }
    function Footer() {
        return (
            <footer>
                {subdomain ?
                <p className='pointer' onClick={navigateToHome}><span className='triangle rotated-270'>&#9650;</span>Tous les motuz'</p>
                :<p className='triangle'>&#9650;</p>}
            </footer>
        )
    }
}
