import React from 'react'
import axios from 'axios'

import './logout.scss'

const proxy = ''

export default function Logout() {

    const handleLogout = async () => {
        try {
            await axios.get(`${proxy}/user/logout`)
            localStorage.removeItem('firstlogin')
            window.location.href = "/login";
        } catch (err) {
            window.location.href = "/login";
        }
    }
    return (
        <div id="logout" title="Logout account" onClick={handleLogout}>
            Logout
        </div>
    )
}
