import TimeAgo from 'javascript-time-ago'
import es from 'javascript-time-ago/locale/es.json'

TimeAgo.addDefaultLocale(es)

const fx = {
    getAge: (dateString) => {
        if(!dateString) return ''
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    },
    parseToLocaleSpanish: (date) => {
        if(!date) return ''
        const parsed = new Date(date).toLocaleString('es-ES', { 
            weekday: 'long', day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute:'2-digit', second:'2-digit' })
        return parsed.charAt(0).toUpperCase() + parsed.slice(1)
    },
    parseToLocaleDateSpanish: (date) => {
        if(!date) return ''
        const parsed = new Date(date).toLocaleString('es-ES', { 
            weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' })
        return parsed.charAt(0).toUpperCase() + parsed.slice(1)
    },
    parseToLocaleEnglish: (date) => {
        if(!date) return ''
        const parsed = new Date(date).toLocaleString('en-US', { 
            weekday: 'long', day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute:'2-digit', second:'2-digit' })
        return parsed.charAt(0).toUpperCase() + parsed.slice(1)
    },
    parseToLocaleDateEnglish: (date) => {
        if(!date) return ''
        const parsed = new Date(date).toLocaleString('en-US', { 
            weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' })
        return parsed.charAt(0).toUpperCase() + parsed.slice(1)
    },
    timeago: (date) => {
        if(!date) return ''
        const timeago = new TimeAgo()
        return timeago.format(new Date(date))
    },
    symbol: (name) => {
        switch (name) {
            case 'USD':
                return '$'
            case 'PEN':
                return 'S/.'
            default:
                break;
        }
    },
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    math: {
        timesStringPrice: (s, factor) => {
            's is a string in the format N*.NN'
            const [integer, decimal] = s.split('.')
            const nonDecimal = (Number(integer) * 100 + Number(decimal)) * factor
            return String(nonDecimal).slice(0,-2) + '.' + String(nonDecimal).slice(-2)
        }
    }
}

export default fx