import React, {useState, useEffect, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {dispatchLogin, fetchUser, dispatchGetUser} from '../../../../../redux/actions/authAction'
import axios from 'axios'
import Profile from '../Profile'
import './general.scss'
import { errMsg, successMsg, showMsgInSeconds} from '../../../utils/msg/Msg'
import Loader from '../../../utils/loader/Loader'

const proxy = ''

const msgDuration = 3

const initData = {
    user: {
        username: '',
        name: '',
        lastName: '',
        email: '',
        password: '',
        avatar: '',
    }
}

const initMsg = {err: '', success: ''}

export default function General() {
    // User
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const token = useSelector(state => state.token)
    const {user} = auth

    const [data, setData] = useState(initData)
    const {avatar} = data
    const [msg, setMsg] = useState({user:initMsg})

    const [n, setN] = useState(0)

    // Loading
    const [loadingPage, setLoadingPage] = useState(true)
    const [loadingAvatar, setLoadingAvatar] = useState(false)

    //CB
    const [callback, setCallback] = useState(false)

    useEffect(() => {
        if(token){
            const getUser = () => {
                dispatch(dispatchLogin())
                return fetchUser(token).then(res => {
                    dispatch(dispatchGetUser(res))
                    setLoadingPage(false)
                })
            }
            getUser()
        }
    }, [token, dispatch, callback])

    const handleUpdate = async(e) => {
        e.preventDefault()
        const {id, value} = e.target
        const [input, prop] = id.split(' ')

        if(value === user[prop]) return
        if(!value) return document.getElementById(id).value = user[prop]
        const confirmContinue = window.confirm(`Update field with '${value.trim()}' ?`)
        if(!confirmContinue) return document.getElementById(id).value = user[prop]

        try {
            const res = await axios.put(`${proxy}/user/update/${user._id}`, {prop, value},{
                headers: { Authorization: token }
            })
            setCallback(!callback)
        } catch (err) {
            err.response.data.msg &&
            alert(err.response.data.msg)
        }
    }
    
    const changeAvatar = async(e) => {
        e.preventDefault()
        const file = e.target.files[0]

        if(!file) {
            setData({...data, avatar:''})
            return showMsgInSeconds(setMsg, {err: "Ningun archivo seleccionado", success: ''}, msgDuration)
        }
        if(file.type !== 'image/jpeg' && file.type !== 'image/png')
            return showMsgInSeconds(setMsg, {err: "Formato incorrecto", success:''}, msgDuration)

        if(file.size > 1024 * 1024)
            return showMsgInSeconds(setMsg, {err: "El archivo es muy pesado", success: ''}, msgDuration)
        
        setData({...data, avatar: file})

        try {
            let formData = new FormData()
            formData.append('file', file)
            const res = await axios.post(`${proxy}/file/upload_avatar`, formData, {
                headers: {
                    Authorization: token,
                    'content-type': 'multipart/form-data'
                }
            })
            console.log(res.data)
            setCallback(!callback)
        } catch (err) {
            err.response.data.msg &&
            alert(err.response.data.msg)
            setLoadingAvatar(false)
        }
    }

    return (
        <Profile>
            
        {loadingPage ? <div style={{fontSize: '100px'}}><Loader/></div>
        :
        <div id='general' className='auth-wrap'>
            <section>
                <div className='title-container'>
                    <div className='flexrow align-ce'><p className='title'>User Information</p></div>
                    <div className="msg">
                        {msg.user.err && errMsg(msg.user.err)}
                        {msg.user.success && successMsg(msg.user.success)}
                    </div>
                </div>
                <form>
                    <div className='input-wrap'>
                        <label>Username</label>
                        <input type={'text'} defaultValue={user.username} id='text username' onBlur={handleUpdate}/>
                    </div>
                    
                    <div className='input-wrap'>
                        <label>Name</label>
                        <input type={'text'} defaultValue={user.name} id='text name' onBlur={handleUpdate}/>
                    </div>
                    
                    <div className='input-wrap'>
                        <label>Last Names</label>
                        <input type={'text'} defaultValue={user.lastName} id='text lastName' onBlur={handleUpdate}/>
                    </div>
                    
                    <div className='input-wrap'>
                        <label>Email</label>
                        <input type={'text'} disabled defaultValue={user.email}/>
                    </div>

                    <div className='input-wrap al-fl-st'>
                        <label>Avatar</label>
                        <div className='v-card'>
                            <input type={'file'} id='avatar-input'onChange={changeAvatar}/>
                            <img id='avatar' title='Change avatar' src={avatar ? URL.createObjectURL(avatar) : user.avatar} alt='user-avatar' onClick={()=>document.getElementById('avatar-input').click()}/>
                        </div>
                    </div>
                </form>
            </section>
        </div>
        }
        </Profile>
    )
}
