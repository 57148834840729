import { useState, useEffect } from "react"
import axios from 'axios'

import './gamesPortal.scss'
import Loading from "../../utils/loading/Loading"

const proxy = ''

export default function GamesPortal(){
    const [games, setGames] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getGames = async() => {
            try {
                const res = await axios.get(`${proxy}/data/games/get/published`)
                //console.log(res.data)
                setGames(res.data.games)
                setLoading(false)
            } catch (err) {
                err.response.data.msg &&
                alert(err.response.data.msg)
                setLoading(false)
            }
        }
        getGames()
    }, []) 

    const navigateTo = (subdomain) => {
        window.location.href = `https://${subdomain}.motuz.fr`
    }
    
    return (
        <div id='games-portal'>
            <div className="title">Les jeux de mots du jour</div>
            {loading ? <Loading />
            :
            !games ?
            <div className="not-found">No games published yet</div>
            :
            <div className="games-list">
            {games.map((game,idx) => {
                return (
                    <div key={idx} className="game-card" onClick={()=>navigateTo(game.subdomain)}>
                        <div className="game-card-logo" style={{backgroundImage: `url(${game.logo})`}}></div>
                        <div className="game-card-text">
                            <p className="bold-text">{game.subtitle}</p> 
                            <p className="light-text">{game.claim}</p>
                        </div>
                    </div>
                )
            })}
            </div>}
        </div>
    )
}