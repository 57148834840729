import React, {useState, useEffect} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {dispatchLogin, fetchUser, dispatchGetUser} from '../../../../redux/actions/authAction'

import Logout from '../../auth/logout/Logout'

import './profile.scss'
import Loader from '../../utils/loader/Loader'

const pages = [
    'general',
    'games',
    'daily-words',
    'stats'
]

export default function Profile(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const auth = useSelector(state => state.auth)
    const token = useSelector(state => state.token)
    const {user} = auth

    const [showUserPanel, setShowUserPanel] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(token){
            const getUser = () => {
                dispatch(dispatchLogin())

                return fetchUser(token).then(res => {
                    dispatch(dispatchGetUser(res))
                    setLoading(false)
                })
            }
            getUser()
        }
    }, [token, dispatch])

    const handleClick = e => {
        const {id} = e.target
        navigate(`/profile/${id}`)
    }

    const handleToogle = ()=> {
        setShowUserPanel(!showUserPanel)
    }

    return (
        <div id="profile">
            <header>
                <p className='logo'><Link to="/">Dashboard</Link></p>
                <div className='non-logo'>
                    <div className="avatar pointer">
                        <img src={user.avatar} alt="avatar" onClick={handleToogle}/>
                    </div>
                    {showUserPanel &&
                    <div className='user-panel'>
                        <p className='title'><Link to='/profile/general'>{user.username}</Link></p>
                        <div className="list">
                            <Logout />
                        </div>
                    </div>
                    }
                </div>
            </header>
            {/*<div className="nav-bar">
                {pages.map(page => {
                    return(
                        <div className='nav-button' id={page} key={page} onClick={handleClick}
                        style={(page === window.location.pathname.split('/')[2])? {outline: '2px solid #000'} : null} >
                            {page.toUpperCase()}
                        </div>
                    )
                })}
            </div>*/}
            <div className='profile-body'>
                <Sidebar />
                <div className='page'>
                    <div>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
    function Sidebar() {
        return (
            <div id='sidebar'>
                {pages.map(page => {
                    return(
                        <div className='nav-button' id={page} key={page} onClick={handleClick}
                        style={(page === window.location.pathname.split('/')[2])? {backgroundColor: '#fff'} : null} >
                            {page.split('-').length > 0 ? page.replace('-',' ').toUpperCase() : page.toUpperCase()}
                        </div>
                    )
                })}
            </div>
        )
    }
}
