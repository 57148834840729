import React from 'react'
import './msg.scss'

export const errMsg = (msg) => {
    return <div className="err">{msg}</div>
}

export const successMsg = (msg) => {
    return <div className="success">{msg}</div>
}

export const alertMsg = (msg) => {
    return <div className="alert">{msg}</div>
}

export const showMsgInSeconds = (set, msg, prop, newMsg, duration ) => {
    if(!newMsg.err && !newMsg.success && !newMsg.alert) return
    set({...msg, [prop]: newMsg})
    setTimeout(() => set({...msg, [prop] : {err: '', success: '', alert: ''}}), duration*1000);
}

