import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {dispatchLogin, fetchUser, dispatchGetUser} from '../../../../../redux/actions/authAction'
import axios from 'axios'
import { errMsg, successMsg, showMsgInSeconds} from '../../../utils/msg/Msg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import fx from '../../../utils/fx'

import Profile from '../Profile'

import './stats.scss'
import Loader from '../../../utils/loader/Loader'

const proxy = ''

const msgDuration = 3

const initMsg = {err: '', success: ''}

export default function Stats() {
    const dispatch = useDispatch()
    const token = useSelector(state => state.token)
    const [visitors, setVisitors] = useState([])
    
    // Date
    const today = new Date()

    // Msg
    const [msg, setMsg] = useState({visitors: initMsg})

    // Loading
    const [loadingPage, setLoadingPage] = useState(true)
    const [loadingVisitors, setLoadingVisitors] = useState(true)

    //CB
    const [cbUser, setCbUser] = useState(false)
    const [cbVisitors, setCbVisitors] = useState(false)


    useEffect(() => {
        if(token){
            const getUser = () => {
                dispatch(dispatchLogin())
                return fetchUser(token).then(res => {
                    dispatch(dispatchGetUser(res))
                })
            }
            getUser()
        }
    }, [token, dispatch, cbUser])

    useEffect(() => {
        const getVisitors = async() => {
            try {
                const res = await axios.get(`${proxy}/data/visitors/get/all-data`, {
                    headers: { Authorization: token }
                })
                console.log(res.data)
                setVisitors(res.data.visitors)
                setLoadingPage(false)
                setLoadingVisitors(false)
            } catch (err) {
                err.response.data.msg &&
                showMsgInSeconds(setMsg, msg, 'visitors', {err: err.response.data.msg, success: ''}, msgDuration)
                setLoadingPage(false)
                setLoadingVisitors(false)
            }
        }
        if(token) getVisitors()
    }, [token, cbVisitors])
    
    return (
        <Profile>
            {loadingPage ? <div style={{fontSize: '100px'}}><Loader/></div>
            :
            <div id='stats'  className='auth-wrap'>
                <section>
                    <div className='title-container'>
                        <div className='flexrow align-ce'>
                            <p className='title'>Visitors</p>        
                            <div className="msg">
                                {msg.visitors.err && errMsg(msg.visitors.err)}
                                {msg.visitors.success && successMsg(msg.visitors.success)}
                            </div>
                        </div>
                    </div>

                    <div className='table-container'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Online</th>
                                    <th>Today</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            {!loadingVisitors &&
                            <tbody>
                                {visitors?.length === 0? 
                                <tr>
                                    <td colSpan={100}>
                                        <p>No data found</p>
                                    </td>
                                </tr>
                                :visitors?.map((el) => {
                                    return (
                                        <tr key={el._id} className='table-row-data'>
                                            <td>{el.name}</td>
                                            <td>{el.online}</td>
                                            <td>{el.today}</td>
                                            <td>{el.total}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>}
                        </table>
                        {loadingVisitors && 
                        <div className='loader-visitors'>
                            <Loader />
                        </div>}
                    </div>
                </section>
            </div>
            }
        </Profile>
    )
}
