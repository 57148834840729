import React from 'react'
import {Routes, Route} from 'react-router-dom'
import './body.scss'

import Login from './auth/login/Login'
import Register from './auth/register/Register'
import Activation from './auth/activation/Activation'
import Forgot from './auth/forgot/Forgot'
import ResetPassword from './auth/resetPassword/ResetPassword'
import General from './auth/profile/general/General'
import GamePage from './non-auth/gamePage/GamePage'

import Games from './auth/profile/games/Games'
import Daily from './auth/profile/daily/Daily'
import Stats from './auth/profile/stats/Stats'

export default function Body() {
    return (
        <Routes>

            <Route exact path="/" element={<GamePage />} />
            <Route exact path="/login" element={<Login />}/>
            <Route exact path="/register" element={<Register />}/>
            <Route exact path="/activation/:token" element={<Activation />}/>

            <Route exact path="/forgot" element={<Forgot/>}/>
            <Route exact path="/reset-pass/:token" element={<ResetPassword />}/>

            <Route exact path="/profile/general" element={<General />} />
            <Route exact path="/profile/games" element={<Games />} />
            <Route exact path="/profile/daily-words" element={<Daily />} />
            <Route exact path="/profile/stats" element={<Stats />} />
        </Routes>
    )
}
