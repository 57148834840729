import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {isEmail, isEmpty, isLength, isMatch} from '../../utils/validation'
import {errMsg, successMsg, showMsgInSeconds} from '../../utils/msg/Msg'

import './register.scss'

const proxy = ''

const initialState = {
    username: '',
    email: '',
    password: '',
    cf_password: '',
}

const msgDuration = 2

export default function Register() {
    const [user, setUser] = useState(initialState)
    const {username, email, password, cf_password} = user
    const [avatar, setAvatar] = useState('')

    const [msg, setMsg] = useState({err: '', success:''})
    const {err, success} = msg

    const handleChange = (e) => {
        const {name, value} = e.target
        setUser({...user, [name]:value})
        setMsg({err: '', success:''})
    }

    const handleSubmit = async(e) => {
        e.preventDefault()
        if(isEmpty(username) || isEmpty(password))
            return showMsgInSeconds(setMsg, {err: "Please complete all fields", success: ''}, msgDuration)
        if(!isEmail(email))
            return showMsgInSeconds(setMsg, {err: "invalid email", success: ''}, msgDuration)
        if(isLength(password))
            return showMsgInSeconds(setMsg, {err: "Password must have at least 6 characters", success: ''}, msgDuration)
        if(!isMatch(password, cf_password))
            return showMsgInSeconds(setMsg, {err: "Passwords do not match", success: ''}, msgDuration)
        
        let avatarURL = ''
        if(avatar){
            try {
                let formData = new FormData()
                formData.append('file', avatar)
                const res = await axios.post(`${proxy}/image/upload_avatar`, formData, {
                    headers: {'content-type': 'multipart/form-data'}
                })
                avatarURL = res.data.url
            } catch (err) {
                console.log(err.response.data.msg)
                err.response.data.msg &&
                setMsg({err:err.response.data.msg, success: ''})
            }
        }

        try{
            const res = await axios.post(`${proxy}/user/register`, {username, email, password, avatar: avatarURL})
            setMsg({err:'', success: res.data.msg})
        } catch (err) {
            console.log(err.response.data.msg)
            err.response.data.msg &&
            setMsg({err:err.response.data.msg, success: ''})
        }
    }
    const changeAvatar = (e) => {
        e.preventDefault()
        setMsg({err: '', success:''}) 
        const file = e.target.files[0]

        if(!file) {
            setAvatar('')
            return showMsgInSeconds(setMsg, {err: "No image file selected", success: ''}, msgDuration)
        }
        if(file.type !== 'image/jpeg' && file.type !== 'image/png')
            return showMsgInSeconds(setMsg, {err: "Incorrect format", success:''}, msgDuration)

        if(file.size > 1024 * 1024)
            return showMsgInSeconds(setMsg, {err: "File is too large", success: ''}, msgDuration)      
        setAvatar(file)   
    }
    const resetAvatar = () => {
        setAvatar('')
    }

    return (
        <div id="register" className='pre-auth-wrap'>
                <header>
                    <div className='flexcol'>
                        <p className="title">Register Page</p>
                        <p className="subtitle">Please fill in the spaces with your information</p>
                    </div>
                </header>
                <div className="pre-auth-body">
                    <form onSubmit={handleSubmit}>
                            <div className="text-fields">
                                <div className="input-wrap">
                                    <label htmlFor="username">Username*</label>
                                    <input type="text" id="username" name="username" placeholder="Enter your username" onChange={handleChange}/>
                                </div>
                                <div className="input-wrap">
                                    <label htmlFor="email">Email*</label>
                                    <input type="email" id="email" name="email" placeholder="Enter your email" onChange={handleChange}/>
                                </div>
                                <div className="input-wrap">
                                    <label htmlFor="password">Password*</label>
                                    <input type="password" id="password" name="password" placeholder="Enter your password"  onChange={handleChange}/>
                                </div>
                                <div className="input-wrap">
                                    <label htmlFor="cf_password">Confirm password*</label>
                                    <input type="password" id="cf_password" name="cf_password" placeholder="Enter your password"  onChange={handleChange}/>
                                </div>
                                <p className="required-field">* Required field</p>
                            </div>
                        <button type="submit">Register</button>
                    </form>
                </div>
        </div>
    )
}
