import React, {useState, useEffect} from 'react'
import {useParams, Link} from 'react-router-dom'
import axios from 'axios'
import {errMsg, successMsg} from '../../utils/msg/Msg'
import './activation.scss'

const proxy = ''

export default function Activation() {
    const {token} = useParams()
    const [msg, setMsg] = useState({err:'', success:''})
    const {err, success} = msg

    useEffect(() => {
        if(token){
            const activationEmail = async() => {
                try {
                    const res = await axios.post(`${proxy}/user/activation`, {token})
                    setMsg({err:'', success: res.data.msg})
                } catch (err) {
                    err.response.data.msg && setMsg({err: err.response.data.msg, success: ''})
                }
            }
            activationEmail()
        }
    }, [token])
    return (
        <div id="activation"  className='pre-auth-wrap'>
            <header>
                <p className="title">Email activation</p>
            </header>
            <div className="pre-auth-body">
                <div className='msg'>
                    {err && errMsg(err)}
                    {success && successMsg(success)}
                </div>
                <Link to="/login">Go to Login page</Link>
            </div>
            
        </div>
    )
}
