import React, {useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import axios from 'axios'
import {errMsg, successMsg, showMsgInSeconds} from '../../utils/msg/Msg'
import { isLength, isMatch } from '../../utils/validation'

import './resetPassword.scss'

const proxy = ''

const initialState = {
    password: '',
    cf_password: '',
}

const msgDuration = 2

export default function ResetPassword() {
    const {token} = useParams()
    const [data, setData] = useState(initialState)
    const [msg, setMsg] = useState({err: '', success: ''})
    const {err, success} = msg

    const handleChange = (e) => {
        const {name, value} = e.target
        setData({...data, [name]:value})
        setMsg({err: '', succes: ''})
    }

    const handleResetPass = async() => {
        const {password, cf_password} = data
        if(isLength(password)) 
            return showMsgInSeconds(setMsg, {err: "Password must have at least 6 characters", success: ''}, msgDuration)
        if(!isMatch(password, cf_password)) 
            return showMsgInSeconds(setMsg, {err: "Passwords do not match", success: ''}, msgDuration)

        try {
            const res = await axios.post(`${proxy}/user/reset`, {password},{
                headers: {Authorization: token}
            })
            return setMsg({err: "", success: res.data.msg})
        } catch (err) {
            err.response.data.msg && setData({...data, err: err.response.data.msg, success: ''})
        }
    }

    return (
        <div className='auth' id="reset-pass">
            <header>
                <p className="title">Reset your password</p>
                <p className="subtitle">Hopefully you won't forget this one <i className="far fa-smile-wink"></i></p>
            </header>
            <div className="wrap">
                <div className="msg full-width">
                    {err && errMsg(err)}
                    {success && successMsg(success)}
                </div>
                <div className="input-wrap">
                    <label htmlFor="password">New Password</label>
                    <input type="password" name="password" id="password" value={data.password} onChange={handleChange} />
                </div>
                
                <div className="input-wrap">
                    <label htmlFor="cf_password">Confirm password</label>
                    <input type="password" name="cf_password" id="cf_password" value={data.cf_password} onChange={handleChange} />
                </div>


                <button onClick={handleResetPass}>Reset password</button>

                <Link to="/login">Go to Login page</Link>
            </div>
        </div>
    )
}
